<template>
  <div class="app-container">
    <div class="filter-container">
      <!-- <el-input
        v-model="listQuery.id"
        placeholder="ID"
        style="width: 180px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.email"
        placeholder="邮箱"
        style="width: 200px;"
        class="filter-item"
        size="small"
        clearable
      /> -->
      <el-date-picker
        class="filter-item"
        v-model="dateArr"
        type="datetimerange"
        :default-time="['00:00:00', '23:59:59']"
        align="right"
        value-format="yyyy-MM-dd HH:mm:ss"
        unlink-panels
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
      ></el-date-picker>
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输入用户昵称/电话"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.chain_address"
        placeholder="请输入区块链地址"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.is_normal"
        placeholder="状态"
        style="width: 90px"
        class="filter-item"
        clearable
      >
        <el-option label="正常" :value="1" />
        <el-option label="冻结" :value="0" />
      </el-select>
      <el-select
        v-model="listQuery.is_buy"
        placeholder="是否消费"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option label="已消费" :value="1" />
        <el-option label="未消费" :value="0" />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <!-- <span class="filter-item">
        <download-excel
          :fields="json_fields"
          :fetch="handleExport"
          type="xls"
          name="用户持仓价值排行.xls"
          worksheet="用户持仓价值排行"
          :header="header"
        >
          <el-button type="warning" :loading="exportLoading">
            导出用户持仓价值排行
          </el-button>
        </download-excel>
      </span>
      <span class="filter-item">
        <download-excel
          :fields="json_fields1"
          :fetch="handleExport1"
          type="xls"
          name="用户持仓数量排行.xls"
          worksheet="用户持仓数量排行"
          :header="header"
        >
          <el-button type="warning" :loading="exportLoading1">
            导出用户持仓数量排行
          </el-button>
        </download-excel>
      </span>
      <span class="filter-item">
        <download-excel
          :fields="json_fields2"
          :fetch="handleExport2"
          type="xls"
          name="用户消费次数排行.xls"
          worksheet="用户消费次数排行"
          :header="header"
        >
          <el-button type="warning" :loading="exportLoading2">
            用户消费次数排行
          </el-button>
        </download-excel>
      </span> -->
      <span class="filter-item">
        <download-excel
          :fields="json_fields3"
          :fetch="handleExport3"
          type="xls"
          name="我的下级.xls"
          worksheet="我的下级"
          :header="header"
        >
          <el-button type="warning" :loading="exportLoading3">
            导出我的下级
          </el-button>
        </download-excel>
      </span>
    </div>
    <div style="margin-bottom: 5px">用户数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      fit
      highlight-current-row
      @cell-click="cellClick"
    >
      <el-table-column align="center" label="ID" width="95">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="头像" min-width="60" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.avatar" class="user-avatar" />
        </template>
      </el-table-column>
      <el-table-column label="昵称" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.nickname }}
        </template>
      </el-table-column>
      <el-table-column label="联系电话" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.account }}
        </template>
      </el-table-column>
      <el-table-column label="区块链地址" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.chain_address }}
        </template>
      </el-table-column>
      <el-table-column label="注册时间" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column label="最新活跃时间" min-width="80" align="center">
        <template slot-scope="scope">
          {{
            scope.row.last_login_at
              ? $moment(scope.row.last_login_at * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="邀请码" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.invitation_code || "-" }}
        </template>
      </el-table-column>
      <el-table-column label="累计消费" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.total_pay_money }}
        </template>
      </el-table-column>
      <el-table-column label="实名认证" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_certification === 1" type="primary"
            >是</el-tag
          >
          <el-tag v-else-if="scope.row.is_certification === 0" type="warning"
            >否</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="身份证姓名" min-width="80" align="center">
        <template slot-scope="scope">
          {{
            scope.row.certification_log
              ? scope.row.certification_log.id_card_name
              : "-"
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="性别" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.gender === 0 || scope.row.gender === null">未设置</span>
          <span v-else-if="scope.row.gender === 1">男</span>
          <span v-else-if="scope.row.gender === 2">女</span>
          <span v-else>后端无字段</span>
        </template>
      </el-table-column> -->
      <el-table-column
        class-name="status-col"
        label="用户状态"
        min-width="60"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_normal === 1" type="primary">正常</el-tag>
          <el-tag v-else-if="scope.row.is_normal === 0" type="warning"
            >冻结</el-tag
          >
          <el-tag v-else>后端无字段</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        class-name="status-col"
        label="是否消费"
        min-width="60"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.total_pay_money > 0" type="primary"
            >已消费</el-tag
          >
          <el-tag v-else type="warning">未消费</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        class-name="status-col"
        label="优先购数量"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-input
            ref="gain"
            size="mini"
            v-if="scope.row.isEdit"
            @blur="blurClick(scope)"
            :autofocus="true"
            v-model="scope.row.total_first_buy_num"
            oninput="value = value.replace(/[^\d]/g, '')"
          ></el-input>
          <span v-else>
            {{ scope.row.total_first_buy_num }}<i class="el-icon-edit"></i
          ></span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        align="center"
        prop="created_at"
        label="注册时间"
        min-width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ scope.row.created_at }}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        label="操作"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.is_normal === 1"
            type="warning"
            size="mini"
            @click="handleForbid(scope.row.id, '冻结')"
            >冻结</el-button
          >
          <el-button
            v-else
            size="mini"
            type="primary"
            @click="handleForbid(scope.row.id, '解冻')"
            >解冻</el-button
          >
          <el-button
            v-if="scope.row.is_frist_buy === 1"
            type="warning"
            size="mini"
            @click="changeFirstBuy(scope.row.id, '取消')"
            >取消设为优先购用户</el-button
          >
          <el-button
            size="mini"
            type="success"
            @click="childList(scope.row.id)"
            style="margin-top: 5px"
            v-if="!listQuery.invitation_user_id"
          >
            我的下级
          </el-button>
          <!-- <el-button
            v-else
            size="mini"
            type="primary"
            @click="changeFirstBuy(scope.row.id, '分配')"
            >设为优先购用户</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import downloadExcel from "vue-json-excel";
export default {
  components: { downloadExcel },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        id: "",
        email: "",
        nickname: "",
        is_normal: "",
        type: 1,
      },
      dateArr: null,
      //导出
      json_fields: {
        排名: {
          field: "ranking",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        手机号: {
          field: "account",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        持仓价值: {
          field: "total_price",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
      },
      exportLoading: false,
      header: "",
      //导出1
      json_fields1: {
        排名: {
          field: "ranking",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        手机号: {
          field: "account",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        持仓数量: {
          field: "total_num",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
      },
      exportLoading1: false,
      //导出1
      json_fields2: {
        排名: {
          field: "ranking",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        手机号: {
          field: "account",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        消费次数: {
          field: "total_num",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
      },
      exportLoading2: false,
      //导出1
      json_fields3: {
        ID: {
          field: "id",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        昵称: {
          field: "nickname",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        联系电话: {
          field: "account",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        注册时间: {
          field: "created_at",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        最新活跃时间: {
          field: "last_login_at",
          //自定义回调函数
          callback: (value) => {
            value = value
              ? this.$moment(value * 1000).format("YYYY-MM-DD HH:mm:ss") + ""
              : "-";
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        邀请码: {
          field: "invitation_code",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        累计消费: {
          field: "total_pay_money",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        实名认证: {
          field: "is_certification",
          //自定义回调函数
          callback: (value) => {
            return value == 1 ? "是" : "否";
          },
        },
        身份证姓名: {
          field: "certification_log",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value
              ? value.id_card_name
              : "-" + "</p>";
          },
        },
        身份证号: {
          field: "certification_log",
          //自定义回调函数
          callback: (value) => {
            if (value) {
              value = value.id_card_no;
            }
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        用户状态: {
          field: "is_normal",
          //自定义回调函数
          callback: (value) => {
            return value == 1 ? "正常" : "冻结";
          },
        },
        是否消费: {
          field: "total_pay_money",
          //自定义回调函数
          callback: (value) => {
            return value > 0 ? "已消费" : "未消费";
          },
        },
        优先购数量: {
          field: "total_first_buy_num",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
      },
      exportLoading3: false,
    };
  },
  created() {
    if (this.$route.query.id != "undefined") {
      this.listQuery.invitation_user_id = this.$route.query.id;
    }
    this.getList();
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_at = newVal[0];
        this.listQuery.end_at = newVal[1];
      } else {
        this.listQuery.start_at = "";
        this.listQuery.end_at = "";
      }
    },
  },
  methods: {
    handleExport() {
      this.exportLoading = true;
      // 弹出确认框
      // 弹出框填写拒绝原因
      return this.$prompt("请输入需要的排行人数", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator(value) {
          if (value <= 0) {
            return "请输入正确的排名人数";
          }
        },
      })
        .then(({ value }) => {
          return request({
            url: "/api/backend/userProductRanking",
            method: "get",
            params: { ranking_num: value },
          })
            .then((result) => {
              var json_data = result.data;
              this.header = "用户持仓价值排行";
              return json_data;
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.listLoading = false;
              this.exportLoading = false;
            });
        })
        .finally(() => {
          this.listLoading = false;
          this.exportLoading = false;
        });
    },
    handleExport1() {
      this.exportLoading1 = true;
      // 弹出确认框
      // 弹出框填写拒绝原因
      return this.$prompt("请输入需要的排行人数", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator(value) {
          if (value <= 0) {
            return "请输入正确的排名人数";
          }
        },
      })
        .then(({ value }) => {
          return request({
            url: "/api/backend/userProductNumRanking",
            method: "get",
            params: { ranking_num: value },
          })
            .then((result) => {
              var json_data = result.data;
              this.header = "用户持仓数量排行";
              return json_data;
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.listLoading = false;
              this.exportLoading1 = false;
            });
        })
        .finally(() => {
          this.listLoading = false;
          this.exportLoading1 = false;
        });
    },
    handleExport2() {
      this.exportLoading2 = true;
      // 弹出确认框
      // 弹出框填写拒绝原因
      return this.$prompt("请输入需要的排行人数", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator(value) {
          if (value <= 0) {
            return "请输入正确的排名人数";
          }
        },
      })
        .then(({ value }) => {
          return request({
            url: "/api/backend/userConsumptionNumRanking",
            method: "get",
            params: { ranking_num: value },
          })
            .then((result) => {
              var json_data = result.data;
              this.header = "用户持仓数量排行";
              return json_data;
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.listLoading = false;
              this.exportLoading2 = false;
            });
        })
        .finally(() => {
          this.listLoading = false;
          this.exportLoading2 = false;
        });
    },
    handleExport3() {
      this.exportLoading3 = true;
      let params = JSON.parse(JSON.stringify(this.listQuery));
      params.limit = 9999999999;
      // 弹出确认框
      // 弹出框填写拒绝原因
      return request({
        url: "/api/backend/users",
        method: "get",
        params: params,
      })
        .then((result) => {
          var json_data = result.data.data;
          this.header = "用户持仓数量排行";
          return json_data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.listLoading = false;
          this.exportLoading3 = false;
        });
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/users",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    cellClick(row, column, event) {
      if (column.label === "优先购数量") {
        this.$set(row, "isEdit", true);
      }
      this.$forceUpdate();
    },
    blurClick({ row, column }) {
      this.$set(row, "isEdit", false);
      if (row.total_first_buy_num > 19999999999) {
        this.$message.error("优先购数量不能大于19999999999");
        return;
      }
      if (row.total_first_buy_num < 0) {
        this.$message.error("优先购数量必须大于或者等于0");
        return;
      }
      this.$confirm("此操作将更新该用户的优先购数量, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/users/updateFirstBuyNum",
            method: "post",
            data: { id: row.id, total_first_buy_num: row.total_first_buy_num },
          }).then(() => {
            this.getList();
            this.$forceUpdate(); //强制更新
          });
        })
        .catch(() => {
          this.getList();
        });
    },
    handleForbid(val, text) {
      this.$confirm("此操作将" + text + "该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/users/changeNormal",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    changeFirstBuy(val, text) {
      this.$confirm("此操作将" + text + "该用户优先购权限, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/users/changeFirstBuy",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    childList(id) {
      this.$router.push(`/user/childList?id=${id}`);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
